// @import '_fonts';
@import '_variables';
@import '_functions';
@import '_config';

@import '_base';
@import '_text';

@import '_grid';
@import '_btn';
@import '_badge';

@import '_logo';
@import '_navigation';
@import '_display';
@import '_stats';
@import '_cards';
@import '_form';
@import '_faqs';

@import '_header';
@import '_banner';
@import '_footer';

.partners {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    .partner {
        border: 1px solid $overlay-05;
        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 1;
        padding: 0 40px;

        img {
            max-width: 100%;
            max-height: 70px;
        }
    }
}

swiper-slide {
    width: auto;
    margin-right: 10px;
}

.login {
    padding-top: 80px;
    img {
        width: 100%;
    }
}

.clients {
    padding-top: 60px;
    padding-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;

    .clients__nav {
        border: 1px solid $base;
        margin-bottom: 20px;
        border-radius: 25px;
        padding: 5px;
        display: inline-flex;

        .clients__nav__item {
            padding: 10px 20px;
            border-radius: 25px;
            display: inline-block;
            cursor: pointer;

            &.active {
                background-color: $base;
                color: #fff;
            }
        }
    }

    .clients__item {
        img {
            max-width: 80%;
            max-height: 40px;
            margin-bottom: 50px;
        }
    }
}

.teams {
    .teams__nav {
        list-style: none;
        display: flex;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, .2);
        border-radius: $border-radius;
        padding: 10px;
        margin: 0;

        .teams__nav__item {
            margin: 0;
            flex: 1;
            font-size: $font-size-lg;
            color: $base;
            padding: 15px 20px;
            border-radius: $border-radius;
            text-align: center;
            transition: all 0.5s;
            cursor: pointer;

            &.active {
                background-color: $base;
                color: $white-100;
            }

            // text-align: center;
        }

        margin-bottom: 50px;
    }

    .teams__list {
        .teams__list__member {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .teams__list__member__image {
                width: 150px;

                img {
                    border-radius: 50%;
                }
            }

            .teams__list__member__desc {
                padding-left: 20px;
            }
        }
    }
}

@media only screen and (max-width:769px) {
    .static_banner {
        .static_banner__inner {
            .hero-stats {
                margin-top: 10px;
            }
        }
    }

    .clients {
        padding-top: 20px;
    }

    .teams {
        .teams__nav {
            margin: 10px 0;
            display: block;
            box-shadow: none;
            flex: auto;
            flex-wrap: wrap;

            .teams__nav__item {
                display: inline-block;
                font-size: $font-size-sm;
                text-align: left;
                padding: 7px 10px;
            }
        }
    }
    .login {
        text-align: center;
    }
}